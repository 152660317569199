<template>
  <div style="margin: 0 auto;">
    <v-chart ref="chart" :forceFit="true" :style="`width:${chartWidth}px;`" :height="height" :data="items" :scale="scale" :padding="[30, 'auto']">
      <v-tooltip :showTitle="false" :item-tpl="tooltipOpts.itemTpl"/>
      <v-axis />
      <v-bar position="year*sales"/>
    </v-chart>
  </div>
</template>

<script>
  const data = [
    { year: '2018-05', sales: 1.25 },
    { year: '2018-06', sales: 1.05 },
    { year: '2018-07', sales: 1.45 },
  ];

  const scale = [{
    dataKey: 'sales',
    //tickInterval: 1,
  }];

  export default {
    props: {
      items: {
        type: Array,
        default: () => ([])
      },
      suffix: {
        type: String,
        default: ''
      },
      prefix: {
        type: String,
        default: ''
      },
      chartWidth: {
        type: Number,
        default: 400
      }
    },
    data() {
      return {
        data,
        scale,
        height: 180,
        tooltipOpts: {
          showTitle: false,
          itemTpl: `<li data-index={index} style="margin-bottom:4px;">
              <span style="background-color:{color};" class="g2-tooltip-marker"></span>
              {title}<br/>
              <span style="padding-left: 16px">${this.prefix}{value}${this.suffix}</span><br/>
              </li>`
        }
      };
    },
    methods: {
      doLayout() {
        setTimeout(() => {
          this.$refs.chart.chart.chartInstance.forceFit()
        }, 300)
      }
    }
  };
</script>
